import React from 'react'

const App = () => {
  return (
    <>
<Header/>
    </>
  )
}

export default App

const Header=() =>{
  return(
    <nav className='flex justify-center items-center bg-pink-500 h-48' >
    <ul className='text-white flex flex-row space-x-6 uppercase'>
      <li>Home</li>
      <li>About</li>
      <li>Service</li>
      <li>Contact</li>
      <li>Email Address</li>
      <li>Country</li>
    </ul>
    </nav>
  )
}